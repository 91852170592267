<template>
  <div class="print-setup">
    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              class="required"
              label="Layout de impressão"
              :items="printLayoutOptions"
              v-model="printLayout"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="success" @click="savePrintSetup()">
          Salvar
          <i class="fa fa-check"></i>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false"
            >Fechar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  name: "PrintSetup",
  data() {
    return {
      loading: false,
      successDialog: false,
      printLayout: null,
      printLayoutOptions: [
        { text: "Sem cabeçalho", value: "v1" },
        { text: "Com cabeçalho", value: "v2" },
      ],
    };
  },
  computed: {},
  methods: {
    async loadPrintSetup() {
      try {
        this.loading = true;

        let url = `/company-branches/${this.$route.params.id}`;

        const res = await this.$axios.get(url);

        this.printLayout = res.data.reportLayout;

        this.loading = false;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async savePrintSetup() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Salvar configurações de impressão?",
            {
              color: "primary",
            }
          ))
        )
          return;

        if (!this.printLayout) {
          this.$root.$errorDialog("Informe o layout de impressão desejado.");
          return;
        }

        this.loading = true;

        //faz requisição
        await this.$axios.put(
          `/company-branches/${this.$route.params.id}/medical-report-settings`,
          { reportLayout: this.printLayout }
        );

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async handleError(error) {
      console.log(error);
      const errorHandled = errorHandler.treatError(error);

      await this.$root.$errorDialog(errorHandled, {
        width: "800",
        color: "primary",
      });
    },
  },
  created() {
    this.loadPrintSetup();
  },
};
</script>

<style></style>
