<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon
        ><v-btn title="voltar" icon @click="$router.go(-1)"
          ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
        ></v-app-bar-nav-icon
      >
      <v-card-title>Configurações da filial</v-card-title>
    </v-toolbar>

    <v-tabs v-model="tab">
      <v-tab>Horários de funcionamento</v-tab>
      <v-tab>Laudos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item><CompanyBranchOperationHours /></v-tab-item>
      <v-tab-item><MedicalReportsSetup /></v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import CompanyBranchOperationHours from "@/components/CompanyBranchConfigs/CompanyBranchOperationHours.vue";
import MedicalReportsSetup from "@/components/CompanyBranchConfigs/MedicalReportsSetup.vue";

export default {
  components: {
    CompanyBranchOperationHours,
    MedicalReportsSetup,
  },
  data: () => ({
    tab: null,
  }),
};
</script>

<style></style>
