<template>
  <!-- Depois tem que revisar o código de salvar no BD, cliente reclamou de estar incorreto -->
  <div class="operation-hours">
    <v-card flat>
      <v-card-text>
        <v-row
          class="mt-2 d-flex align-center flex-wrap"
          v-bind:key="thisDay.id"
          v-for="thisDay in days"
        >
          <v-col md="4" cols="12">
            <v-switch
              :label="thisDay.day"
              v-model="thisDay.open"
              name="open-in-this-day"
            >
            </v-switch>
          </v-col>
          <v-col md="8" cols="12">
            <v-row
              class="mb-2 d-flex align-center flex-wrap"
              v-bind:key="thisTimeInterval.id"
              v-for="thisTimeInterval in thisDay.timeIntervals"
            >
              <v-col v-if="thisDay.open" sm="5">
                <v-text-field
                  type="time"
                  v-model="thisTimeInterval.startTime"
                ></v-text-field>
              </v-col>
              <v-col v-if="thisDay.open" sm="5">
                <v-text-field
                  type="time"
                  v-model="thisTimeInterval.endTime"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="thisDay.open && thisDay.timeIntervals.length > 1"
                sm="1"
              >
                <v-btn
                  @click="dropInterval(thisDay.value, thisTimeInterval.id)"
                  icon
                  title="Remover este horário"
                >
                  <v-icon> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="thisDay.open" sm="1">
                <v-btn icon @click="newInterval(thisDay.value)">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="success" @click="saveOperationHours()">
          Salvar
          <i class="fa fa-check"></i>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false"
            >Fechar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  name: "OperationHours",
  data() {
    return {
      loading: false,
      successDialog: false,
      OperationHoursData: [],
      days: [
        {
          day: "Domingo",
          value: "sunday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Segunda-feira",
          value: "monday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Terça-feira",
          value: "tuesday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Quarta-feira",
          value: "wednesday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Quinta-feira",
          value: "thursday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Sexta-feira",
          value: "friday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Sábado",
          value: "saturday",
          timeIntervals: [
            {
              id: 1,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
      ],
    };
  },
  computed: {},
  methods: {
    async loadOperationHours() {
      try {
        this.loading = true;

        let url = `/company-branches/${this.$route.params.id}/operation-hours`;

        const res = await this.$axios.get(url);

        if (res.data.length > 0) {
          this.OperationHoursData = JSON.parse(JSON.stringify(res.data));
          this.loadOpenDays();
        }

        this.loading = false;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    loadOpenDays() {
      this.loading = true;

      for (let i = 0; i < this.OperationHoursData.length; i++) {
        let weekDay = this.OperationHoursData[i].weekDay; //pega o id do dia da semana
        //verifica o indice em days para o dia da semana correspondente
        let indexOfDay = this.days.findIndex((d) => d.value === weekDay);
        let lastPos = this.days[indexOfDay].timeIntervals.length - 1; //pega a ultima posição do array de intervalos
        let nextId = this.days[indexOfDay].timeIntervals[lastPos].id + 1; //vê qual o id do ultimo elemento e incrementa

        if (
          this.days[indexOfDay].timeIntervals[lastPos].startTime === "00:00" &&
          this.days[indexOfDay].timeIntervals[lastPos].endTime === "00:00"
        ) {
          //se não tiver ocorrido edição, somente atualiza os valores do intervalo
          this.days[
            indexOfDay
          ].timeIntervals[0].startTime = this.OperationHoursData[i].startTime;
          this.days[
            indexOfDay
          ].timeIntervals[0].endTime = this.OperationHoursData[i].endTime;
        } else {
          //acrescenta um novo intervalo
          this.days[indexOfDay].timeIntervals.push({
            id: nextId,
            startTime: this.OperationHoursData[i].startTime,
            endTime: this.OperationHoursData[i].endTime,
          });
        }

        this.days[indexOfDay].open = true;
      }

      this.loading = false;
    },
    newInterval(value) {
      let dayIndex = this.days.findIndex((d) => d.value === value);
      let lastPos = this.days[dayIndex].timeIntervals.length - 1;
      let nextId = this.days[dayIndex].timeIntervals[lastPos].id + 1;
      this.days[dayIndex].timeIntervals.push({
        id: nextId,
        startTime: "00:00",
        endTime: "00:00",
      });
    },
    dropInterval(dayValue, intervalId) {
      let dayIndex = this.days.findIndex((d) => d.value === dayValue);
      let intervalIndex = this.days[dayIndex].timeIntervals.findIndex(
        (i) => i.id === intervalId
      );
      this.days[dayIndex].timeIntervals.splice(intervalIndex, 1);
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    async saveOperationHours() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente salvar o horário de funcionamento?",
            {
              color: "primary",
            }
          ))
        )
          return;

        //valida
        let daysAux = JSON.parse(JSON.stringify(this.days));
        let openDays = [];

        for (const day of daysAux) {
          if (day.open) {
            for (const interval of day.timeIntervals) {
              openDays.push({
                weekDay: day.value,
                startTime: interval.startTime,
                endTime: interval.endTime,
              });
            }
          }
        }

        if (openDays.length === 0) {
          this.$root.$errorDialog(
            "Informe ao menos um horário de funcionamento"
          );
          return;
        }

        this.loading = true;

        //faz requisição
        await this.$axios.put(
          `/company-branches/${this.$route.params.id}/operation-hours`,
          openDays
        );

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async handleError(error) {
      console.log(error);
      const errorHandled = errorHandler.treatError(error);

      await this.$root.$errorDialog(errorHandled, {
        width: "800",
        color: "primary",
      });
    },
  },
  created() {
    this.loadOperationHours();
  },
};
</script>

<style></style>
